import './style.css';
import Navbar from "../../components/navbar/index.jsx";
import api from "../../services/api.js";
import { useState, useEffect } from "react";
import {StatusPedido} from "../../services/functions.js";


function Home(){

    const [qtdPedido, setQtdPedido] = useState(0);
    const [vlPedido, setVlPedido] = useState(0);
    const [qtdCliente, setQtdCliente] = useState(0);
    const [pedidos, setPedidos] = useState([]);

    useEffect(() => {

        api.get('/admin/usuarios/dashboard')
            .then(response => {            
                setQtdPedido(response.data.qtd_pedido_dia);
                setVlPedido(response.data.vl_total_dia);
                setQtdCliente(response.data.qtd_cliente_novo);
            })
            .catch(err => {
                console.log(err);
            });

        api.get('/admin/pedidos/dashboard')
            .then(response => {            
                setPedidos(response.data);
            })
            .catch(err => {
                console.log(err);
            });

    }, []);

    return <>
        <Navbar tela="/" />

        <div className="container-fluid mt-page">

            <div className="m-2 mt-4 d-flex justify-content-between">
                <h2>Dashboard</h2>            
            </div>  

            <div className="row">

                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header">
                            Pedidos do Dia
                        </div>
                        <div className="card-body text-center">
                            <h2 className="card-title">{qtdPedido}</h2>
                            <p className="card-text">{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(vlPedido)}</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card">
                        <div className="card-header">
                            Clientes Novos
                        </div>
                        <div className="card-body text-center">
                            <h2 className="card-title">{qtdCliente}</h2>
                            <p className="card-text">(mês atual)</p>
                        </div>
                    </div>
            </div>

            </div>

            <div className="m-2 mt-5 d-flex justify-content-between">
                <h2>Últimos Pedidos</h2>            
            </div>

            <div className="row ms-3 me-3">
                <table className="table">
                    <thead>
                        <tr>
                        <th scope="col">Pedido</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Status</th>
                        <th scope="col">Bairro</th>
                        <th scope="col">Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pedidos.map(function (pedido){
                                return <tr key={pedido.id_pedido}>
                                    <th scope="row">{pedido.id_pedido}</th>
                                    <td>{pedido.nome}</td>
                                    <td>{StatusPedido(pedido.status)}</td>
                                    <td>{pedido.bairro}</td>
                                    <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(pedido.vl_total)}</td>
                                </tr>
                            })
                        }                                                
                    </tbody>
                </table>
            </div>


        </div>
    </>
}

export default Home;