import axios from "axios";

const URL = "https://api-delivery-f4f89043695c.herokuapp.com";

const api = axios.create({
    baseURL: URL
});

api.interceptors.request.use(req => {
    if (localStorage.getItem('sessionToken')) {
      req.headers.Authorization = `Bearer ${localStorage.getItem('sessionToken')}`;
    }
    
    return req;
  },
  (err) => {
    console.log(err);
  }
);

api.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
      localStorage.removeItem('sessionToken');

      if (!document.location.href.includes('/login', 1) ) {
        document.location = '/login';
      }
   }
   return error;
 });

export default api;