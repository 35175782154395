import "./style.css";
import {StatusPedido} from "../../services/functions.js";
import api from "../../services/api.js";

function Pedido(props){

    function AlterarStatus(st){
        api.put('admin/pedidos/status/' + props.id_pedido, {
            status: st
        }).then(response =>{
            props.onClickStatus();
        }).catch(err =>{            
            console.log(err);
        });
    }

    const dt_ped = new Date(props.dt_pedido);
    const formattedDate = new Intl.DateTimeFormat('pt-BR', {dateStyle: 'short'}).format(dt_ped);
    const formattedTime = new Intl.DateTimeFormat('pt-BR', {timeStyle:'short'}).format(dt_ped);

    return <div className="d-flex justify-content-between shadow-lg pedido me-4" key={props.id_pedido}>
        <div>
            <span><b>Pedido #{props.id_pedido}</b></span>
            <span className="badge rounded-pill bg-secondary ms-3">
                {formattedDate} - {formattedTime}
            </span>
            <span className="badge rounded-pill bg-danger ms-3">{StatusPedido(props.status)}</span>
            <small className="d-block mt-1 mb-3 text-secondary">
                {props.nome} - {props.endereco}  
                {props.complemento && ` - ${props.complemento}`} - 
                {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(props.vl_total)}
            </small>

            {
                Array.isArray(props.itens) && props.itens.map(function(item){
                    return <div className="col-12" key={item.id_item}>
                        <div className="row p-3 ps-0 border-top">
                            <div className="col-3">
                                <img className="img-fluid rounded img-produto" src={item.url_foto} alt="Produto" />
                            </div>
                            <div className="col-9">
                                <div className="d-flex justify-content-between align-items-center">
                                    <small><b>{item.descricao}</b></small>
                                    <small><b>{new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(item.vl_unit * item.qtd)}</b></small>
                                </div>
                                <small className="d-block mb-2">
                                    {item.qtd.toLocaleString('pt-BR', {minimumIntegerDigits: 2})} x 
                                    {new Intl.NumberFormat('pt-BR', {style: 'currency',currency: 'BRL'}).format(item.vl_unit)}
                                </small>
                                {
                                    item.detalhes && item.detalhes.map(detalhe => (
                                        <small className="d-block text-secondary" key={detalhe.nome}>- {detalhe.nome}</small>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                })
            }
        </div>

        <div className="d-flex align-items-center me-4">
            {
                props.status !== "F" ? (
                    <div className="dropdown">
                        <a className="btn btn-secondary dropdown-toggle" 
                           role="button" 
                           id={`dropdownMenuLink${props.id_pedido}`} 
                           data-bs-toggle="dropdown" 
                           aria-expanded="false">
                            Status
                        </a>                
                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuLink${props.id_pedido}`}>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("A")}>Aguardando</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("P")}>Em produção</a></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("E")}>Saiu para entrega</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("F")}>Finalizar</a></li>
                        </ul>
                    </div>
                ) : (
                    <button className="btn btn-outline-danger ms-4" onClick={() => AlterarStatus("A")}>
                        <i className="fas fa-arrow-left"></i>Reabrir Pedido
                    </button>
                )
            }
        </div>
    </div>
}

export default Pedido;
