import Navbar from "../../components/navbar/index.jsx";
import Produto from "../../components/produto/index.jsx";
import Categoria from "../../components/categoria/index.jsx";
import CategoriaModal from "../../components/categoria-modal/index.jsx";
import ProdutoModal from "../../components/produto-modal/index.jsx";
import ProdutoOpcionalModal from "../../components/produto-opcional-modal/index.jsx";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import './style.css';
import { useState } from "react";
import { useEffect } from "react";
import api from "../../services/api.js";

function Cardapio(){
 
    const [isCategoriaOpen, setIsCategoriaOpen] = useState(false);
    const [dados_categoria, setDados_Categoria] = useState({id_categoria: 0, categoria: "", ind_ativo: "S"});

    const [isProdutoOpen, setIsProdutoOpen] = useState(false);
    const [dados_produto, setDados_Produto] = useState({id_produto: 0, id_categoria: 0, nome: "", descricao: "", 
                                                        url_foto: "", url_logo: "", vl_produto: 0,
                                                        vl_promocao: 0, ind_ativo: "S"});

    const [isProdutoOpcionalOpen, setIsProdutoOpcionalOpen] = useState(false);
    const [idProduto, setIdProduto] = useState(0);

    const [categorias, setCategorias] = useState([]);
    const [cardapio, setCardapio] = useState([]);
    

    function OpenModalCategoria(id){

        if (id > 0) {
            api.get(`/admin/produtos/categorias/${id}`)
            .then(response => { 
                                
                setDados_Categoria(response.data[0]);
                setIsCategoriaOpen(true);
            })
            .catch(err => {            
                console.log(err);
            });        
            } else {
                setDados_Categoria({id_categoria: 0, categoria: "", ind_ativo: "S"});
                setIsCategoriaOpen(true);
            }            
    }

    function CloseModalCategoria(){
        setIsCategoriaOpen(false);
    }

    function ExcluirCategoria(id){
        
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão da categoria?',
            buttons: [
              {
                label: 'Sim',
                onClick: () => {
                    api.delete(`/admin/produtos/categorias/${id}`)
                        .then(retorno => {
                            if (retorno.response){
                                if (retorno.response.status !== "200"){
                                    alert(retorno.response.data.erro);
                                }
                            } else {    
                                RefreshDados();
                            }
                        })
                        .catch(err => {            
                            console.log(err);
                        }); 
                }
              },
              {
                label: 'Não',
                onClick: () => {}
              }
            ]
          });
        
    }

    function OpenModalProduto(id, id_cat){

        if (id > 0){
            api.get(`/admin/produtos/${id}`)
            .then(response => {                 
                setDados_Produto(response.data[0]);
                setIsProdutoOpen(true);
            })
            .catch(err => {            
                console.log(err);
            });        
        } else {
            setDados_Produto({id_produto: 0, id_categoria: id_cat, nome: "", descricao: "", url_foto: "", 
                                vl_produto: 0, vl_promocao: 0, ind_ativo: "S"});
            setIsProdutoOpen(true);
        }                   
    }

    function CloseModalProduto(){
        setIsProdutoOpen(false);
    }

    function ExcluirProduto(id){
        
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão do produto?',
            buttons: [
              {
                label: 'Sim',
                onClick: () => {
                    api.delete(`/admin/produtos/${id}`)
                        .then(retorno => {                                             
                            if (retorno.response){
                                if (retorno.response.status !== "200"){
                                    alert(retorno.response.data.erro);
                                }
                            } else {
                                ListarCardapio();
                            }
                        })
                        .catch(err => {            
                            if (err.response) {
                                alert(err.response.data.erro);
                            } else {
                                alert('Ocorreu um erro na requisição.');
                            }
                        }); 
                }
              },
              {
                label: 'Não',
                onClick: () => {}
              }
            ]
          });
        
    }

    function OpenModalProdutoOpcional(id){
        setIdProduto(id);
        setIsProdutoOpcionalOpen(true);
    }

    function CloseModalProdutoOpcional(){
        setIsProdutoOpcionalOpen(false);
    }

    function ListarCategoriaProduto(){
        api.get('/admin/produtos/categorias')
        .then(response => {
            setCategorias(response.data);            
        })
        .catch(err => {
            console.log(err);
        });
    }

    function ListarCardapio(){
        api.get('/admin/cardapios')
        .then(response => {
            setCardapio(response.data);            
        })
        .catch(err => {
            console.log(err);
        });
    }

    function RefreshDados(){
        ListarCategoriaProduto();
        ListarCardapio();
    }

    useEffect(() => {
        RefreshDados();        
    }, []);

    return <>
        <Navbar tela="cardapio" /> 

        <CategoriaModal isOpen={isCategoriaOpen}
                        onRequestClose={CloseModalCategoria} 
                        dados_categoria={dados_categoria}                        
                        onClickSave={RefreshDados}
                        />   

        <ProdutoModal isOpen={isProdutoOpen}
                      onRequestClose={CloseModalProduto}
                      categorias={categorias}
                      dados_produto={dados_produto}
                      onClickSave={ListarCardapio}
                      />   

        <ProdutoOpcionalModal isOpen={isProdutoOpcionalOpen}
                            onRequestClose={CloseModalProdutoOpcional}                            
                            id_produto={idProduto}                            
                            />   

        <div className="container-fluid mt-page">

            <div className="m-2 mt-4 mb-4 d-flex justify-content-between">
                <h2>Cardápio</h2>
                <button className="btn btn-outline-danger ms-4" onClick={() => OpenModalCategoria(0)}><i className="fas fa-plus"></i>Adicionar Categoria</button>
            </div> 

            <div className="m-2 mt-4">
                {
                    cardapio.map(function(item){
                        return <div key={item.id_categoria}>
                            <ul className="list-group">
                            
                            <Categoria key={item.id_categoria}
                                       id_categoria={item.id_categoria} 
                                       nome={item.categoria}
                                       ind_ativo={item.ind_ativo}
                                       onClickEditar={OpenModalCategoria}
                                       onClickExcluir={ExcluirCategoria}
                                       />

                            {
                                item.produtos.map(function (prod){
                                    return <Produto key={prod.id_produto}
                                                    id_produto={prod.id_produto}
                                                    url_foto={prod.url_foto}
                                                    nome={prod.nome}
                                                    valor_unit={prod.vl_produto} 
                                                    valor_promocao={prod.vl_promocao}
                                                    ind_ativo={prod.ind_ativo}
                                                    onClickEditar={OpenModalProduto}
                                                    onClickExcluir={ExcluirProduto}
                                                    onClickOpcional={OpenModalProdutoOpcional}

                                                    />
                                })
                            }                                                                                
                            </ul>
                            <button className="btn btn-outline-danger mt-2 mb-5" onClick={(e) => OpenModalProduto(0, item.id_categoria)}>Adicionar Produto</button>
                        </div>
                    })
                }
            </div>            
        </div>
    </>
}

export default Cardapio;