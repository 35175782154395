import { useState, useEffect } from "react";
import api from '../../services/api';
import Navbar from "../../components/navbar/index.jsx";
import "./style.css";
import { NumericFormat } from "react-number-format";

function Estabelecimento(){

    const [nome, setNome] = useState("");
    const [id_cupom, setId_Cupom] = useState(0);
    const [vl_min_pedido, setVl_Min_Pedido] = useState(0);
    const [vl_min_pedido_default, setVl_Min_Pedido_Default] = useState(0);
    const [vl_taxa_entrega, setVl_Taxa_Entrega] = useState(0);
    const [vl_taxa_entrega_default, setVl_Taxa_Entrega_Default] = useState(0);
    const [endereco, setEndereco] = useState("");
    const [complemento, setComplemento] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [uf, setUF] = useState("");
    const [cod_cidade, setCod_Cidade] = useState("");
    const [cep, setCep] = useState("");
    const [id_categoria, setId_Categoria] = useState(0);
    const [url_foto, setUrl_Foto] = useState("");
    const [url_logo, setUrl_Logo] = useState("");
    const [ind_ativo, setInd_Ativo] = useState(true);

    const [cupons, setCupons] = useState([]);  
    const [cidades, setCidades] = useState([]);  
    const [categorias, setCategorias] = useState([]);  

    const [sucesso, setSucesso] = useState("");

    function ListarCategorias(cod_cid){
        api.get('/v1/categorias?cod_cidade=' + cod_cid)
        .then(response => {
            setCategorias(response.data);            
        })
        .catch(err => {
            console.log(err);
        });
    }

    function HandleCidade(e){        
        const [cid, est] = e.target[e.target.selectedIndex].text.split(" - "); // SAO PAULO - SP

        setCidade(cid);
        setUF(est);
        setCod_Cidade(e.target.value);   
        
        ListarCategorias(e.target.value);
    }

    // useEffect(() =>{
    //     setCod_Cidade()
    // },[HandleCidade])

    useEffect(() => {

        api.get('/v1/cidades')
        .then(response => {
            setCidades(response.data);            
        })
        .catch(err => {
            console.log(err);
        }); 

        api.get('/v1/cupons')
        .then(response => {
            setCupons(response.data);            
        })
        .catch(err => {
            console.log(err);
        }); 

     api.get('/admin/estabelecimentos')
            .then(retorno => {  
                console.log(retorno.data)
                if (retorno.response){
                    if (retorno.response.status !== "200"){
                        alert(retorno.response.data.erro);
                    }
                } else {  

                    setNome(retorno.data.nome);
                    setBairro(retorno.data.bairro);
                    setCep(retorno.data.cep);
                    setCidade(retorno.data.cidade);
                    setCod_Cidade(retorno.data.cod_cidade);
                    setComplemento(retorno.data.complemento);
                    setEndereco(retorno.data.endereco);
                    setId_Categoria(retorno.data.id_categoria);
                    setId_Cupom(retorno.data.id_cupom);
                    setUrl_Foto(retorno.data.url_foto);                    
                    setUrl_Logo(retorno.data.url_logo);
                    setUF(retorno.data.uf); 
                    setVl_Min_Pedido(retorno.data.vl_min_pedido);
                    setVl_Min_Pedido_Default(retorno.data.vl_min_pedido.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
                    setVl_Taxa_Entrega(retorno.data.vl_taxa_entrega);
                    setVl_Taxa_Entrega_Default(retorno.data.vl_taxa_entrega.toLocaleString("pt-BR", { maximumFractionDigits: 2, minimumFractionDigits: 2 }));
                    setInd_Ativo(retorno.data.ind_ativo === "S" ? true : false);                  
                    ListarCategorias(retorno.data.cod_cidade);                     
                }
            })
            .catch(err => {
                console.log(err);
            });  

    }, []);

    function SalvarDados(){
        setSucesso("");

        api.put('admin/estabelecimentos', {
            nome,
            id_cupom,
            vl_min_pedido,
            vl_taxa_entrega,
            endereco,
            complemento,
            bairro,
            cidade,            
            uf,
            cep,
            cod_cidade,
            id_categoria,
            url_foto,
            url_logo,
            ind_ativo: ind_ativo ? "S" : "N"
        }).then(retorno =>{
            if (retorno.response){
                if (retorno.response.status !== "200"){
                    alert(retorno.response.data.erro);
                }
            } else {
                localStorage.setItem('sessionCidade', cidade);
                setSucesso("S");
                
            }

        }).catch(err =>{            
            setSucesso('N');
        });
    }

    return <div className="container-fluid mt-page cardapio">
    <Navbar />
  
    <div className="row col-lg-6 offset-lg-3 form-estabelecimento">
        
        <div className="col-12 mt-4">
            <h2 className="mt-2">Dados do Estabelecimento</h2>     
        </div>

        <div className="col-12 mt-4">           
            <div className="mb-4">
                <label htmlFor="InputNome">Nome do estabelecimento</label>
                <input type="text" onChange={(e) => setNome(e.target.value)} value={nome} className="form-control" id="InputNome" aria-describedby="nome" />                    
            </div>            
        </div>

        <div className="col-md-4">
            <label htmlFor="InputNome">Cupom Desconto</label>
            <div className="form-control mb-2">                
                <select name="cidades" id="cidades" onChange={(e) => setId_Cupom(e.target.value)} value={id_cupom}>
                    <option value="0">Nenhum</option>

                    {cupons.map(c => {
                        return <option key={c.id_cupom} value={c.id_cupom}>{c.cod_cupom}</option>
                    })}

                </select>
            </div>
        </div>

        <div className="col-md-4">
            <div className="mb-4">
                <label htmlFor="InputNome">Vl. Min. Pedido</label>
                
                <NumericFormat  className="form-control" 
                                value={vl_min_pedido_default.toString().replace(".", ",")} 
                                allowLeadingZeros={false} 
                                decimalScale={2}
                                fixedDecimalScale={true}
                                inputMode="decimal"
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix="R$ "                                            
                                onValueChange={(values, sourceInfo) => {
                                    setVl_Min_Pedido(values.value);
                                }} />
            </div> 
        </div>

        <div className="col-md-4">
            <div className="mb-4">
                <label htmlFor="InputNome">Vl. Taxa Entrega</label>
                <NumericFormat  className="form-control" 
                                value={vl_taxa_entrega_default.toString().replace(".", ",")} 
                                allowLeadingZeros={false} 
                                decimalScale={2}
                                fixedDecimalScale={true}
                                inputMode="decimal"
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix="R$ "                                            
                                onValueChange={(values, sourceInfo) => {
                                    setVl_Taxa_Entrega(values.value);
                                }} />
            </div> 
        </div>

        <div className="col-md-8">
            <div className="mb-4">
                <label htmlFor="floatingInput">Endereço</label>
                <input type="text" onChange={(e) => setEndereco(e.target.value)} value={endereco} className="form-control" id="floatingInput"  />
            </div>
        </div>
        
        <div className="col-md-4">
            <div className="mb-4">
                <label htmlFor="floatingInput">Compl.</label>
                <input type="text" onChange={(e) => setComplemento(e.target.value)} value={complemento} className="form-control" id="floatingInput"  />
            </div>
        </div>

        <div className="col-md-6">
            <div className="mb-4">
                <label htmlFor="floatingInput">Bairro</label>
                <input type="text" onChange={(e) => setBairro(e.target.value)} value={bairro} className="form-control" id="floatingInput" />
            </div>
        </div>
        
        <div className="col-md-6">
            <label htmlFor="InputNome">Cidade</label>
            <div className="form-control mb-2">
                <select name="cidades" id="cidades" onChange={HandleCidade} value={cod_cidade}>
                    <option value="0000000">Cidade</option>
                    
                    {cidades.map(c => {
                        return <option key={c.cidade} value={c.cod_cidade}>{c.cidade} - {c.uf}</option>
                    })}  

                </select>
            </div>
        </div>

        <div className="col-md-6">
            <div className="mb-4">
                <label htmlFor="floatingInput">CEP</label>
                <input type="text" onChange={(e) => setCep(e.target.value)} value={cep} className="form-control" id="floatingInput" />
            </div>
        </div>

        <div className="col-md-6">
            <label htmlFor="InputNome">Categoria</label>
            <div className="form-control mb-2">                
                <select name="cidades" id="cidades" onChange={(e) => setId_Categoria(e.target.value)} value={id_categoria} >
                    <option value="0">Categoria</option>
                    
                    {categorias.map(c => {
                        return <option key={c.id_categoria} value={c.id_categoria}>{c.categoria}</option>
                    })}

                </select>
            </div>
        </div>

        <div className="col-12">
            <div className="mb-4">
                <label htmlFor="InputNome">URL Foto</label>
                <input type="text" onChange={(e) => setUrl_Foto(e.target.value)} value={url_foto} className="form-control" id="InputNome" aria-describedby="URL da Foto do estabelecimento" />
            </div>            
        </div>

        <div className="col-12">
            <div className="mb-4">
                <label htmlFor="InputNome">URL Logotipo</label>
                <input type="text" onChange={(e) => setUrl_Logo(e.target.value)} value={url_logo} className="form-control" id="InputNome" aria-describedby="URL do logotipo" />
            </div>            
        </div>

        <div className="col-6 mb-5">
            <div className="form-check">
                <input className="form-check-input" type="checkbox"  onChange={(e) => setInd_Ativo(e.target.checked)} id="defaultCheck1" checked={ind_ativo} />
                <label className="form-check-label" htmlFor="defaultCheck1">
                    Ativo
                </label>
            </div>
        </div>
        
        <div className="col-6 mb-5">
            <div className="d-flex justify-content-end">                    
                <button  type="button" onClick={SalvarDados} className="btn btn-danger mt-3">Salvar Dados</button>
            </div>                      
        </div>

        {sucesso === "S" ? <div className="alert alert-success mt-2 mb-5" role="alert">Dados alterados com sucesso.</div> : null}

    </div>

</div>
}

export default Estabelecimento;