import { useState, useEffect } from "react";
import api from "../../services/api.js";
import Navbar from "../../components/navbar/index.jsx";

function Perfil(){

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [sucesso, setSucesso] = useState("");

    useEffect(() => {

        api.get('/admin/usuarios')
            .then(response => {            
                setNome(response.data.nome);
                setEmail(response.data.email);                
            })
            .catch(err => {
                console.log(err);
            }); 

    }, []);

    function SalvarDados(){
        setSucesso("");

        api.put('admin/usuarios', {
            nome,
            email            
        }).then(retorno =>{
            if (retorno.response){
                if (retorno.response.status != "200"){
                    alert(retorno.response.data.erro);
                }
            } else {
                localStorage.setItem('sessionEmail', email);
                setSucesso("S");
            }

        }).catch(err =>{            
            setSucesso('N');
        });
    }

    return <div className="container-fluid mt-page cardapio">
    <Navbar />
  
    <div className="row col-lg-6 offset-lg-3">
        
        <div className="col-12 mt-4">
            <h2 className="mt-2">Meu Perfil</h2>            
        </div>

        <div className="col-12 mt-4">
            <div>
                <div className="mb-4">
                    <label htmlFor="InputNome" className="form-label">Nome</label>
                    <input type="text" onChange={(e) => setNome(e.target.value)} value={nome} className="form-control" id="InputNome" aria-describedby="nome" />                    
                </div>

                <div className="mb-5">
                    <label htmlFor="InputEmail" className="form-label">E-mail</label>
                    <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" id="InputEmail" aria-describedby="email" />                    
                </div>

                {sucesso == "S" ? <div className="alert alert-success mt-2" role="alert">Dados alterados com sucesso.</div> : null}

                <div className="d-flex justify-content-end">                    
                    <button  type="button" className="btn btn-danger mt-3" onClick={SalvarDados}>Salvar Dados</button>
                </div>

              
            </div>
        </div>

    </div>

</div>
}

export default Perfil;